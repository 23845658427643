import produce from "immer";
import { cloneDeep } from "lodash";
import React, { useEffect, useReducer } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import axiosInstance from "../../../../../Utils/axios";
import ProductViewContainer from "./ProductViewContainer";
import {
  AS_EXPLORE_CATEGORY,
  AS_EXPLORE_VARIANT_CATEGORY,
  AS_SINGLE_EDIT_SAVE,
} from "../../../../../config/api";
import {
  FetchErrorMessage,
  SaveErrorMessage,
  SaveSuccessMessage,
} from "./CategoryCommons";
import { fetchPayload, getValueForKey, handleReplace } from "./helper";
import { resetReducer } from "../../../reducers/exploreCategoryReducer";
import { connect } from "react-redux";

const initialState = {
  isloading: false,
  isSaving: false,
  response: {},
  changesArray: [],
  dropdownStatus: false,
  count: 0,
  dropdownData: [],
};

const reducer = produce((state, action) => {
  switch (action.type) {
    case "INIT":
      return { ...state, isloading: true, response: {}, changesArray: [] };
    case "SUCCESS":
      return {
        ...state,
        isloading: false,
        response: action.payload,
        dropdownData: action.payload.edit_attribute_list_ids || [],
        count: action.payload.count,
      };
    case "ERROR":
      return { ...state, isloading: false, response: {}, count: 0 };
    case "UPDATE_LOADER":
      return { ...state, isloading: action.payload };
    case "SAVE_INIT":
      return { ...state, isSaving: true };
    case "SAVE_SUCCESS":
      return { ...state, isSaving: false, changesArray: {} };
    case "SAVE_ERROR":
      return { ...state, isSaving: false };
    default:
      break;
  }
});

const ProductView = (props) => {
  const history = useHistory();
  const { search, pathname } = useLocation();
  const { l1, l2, batch } = useParams();

  const prepareGridFetch = () => {
    let payload = fetchPayload();
    payload.batch_id = batch;
    payload.l1 = decodeURIComponent(l1);
    payload.l2 = decodeURIComponent(l2);
    payload.view = props.view;
    payload.filters = payload?.filters?.filter(
      ({ id }) => id !== "product__id"
    );
    return payload;
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    let didCancel = false;
    let payload = prepareGridFetch();
    const fetchData = async () => {
      try {
        dispatch({ type: "INIT" });
        const api =
          getValueForKey("type") === "true"
            ? AS_EXPLORE_VARIANT_CATEGORY
            : AS_EXPLORE_CATEGORY;

        const result = await axiosInstance.post(api, payload);
        if (!didCancel) {
          props.resetReducer();
          dispatch({ type: "SUCCESS", payload: result?.data.data });
        }
      } catch (error) {
        dispatch({ type: "ERROR" });
        FetchErrorMessage(fetchData);
      }
    };
    fetchData();
    return () => {
      didCancel = true;
    };
  }, [search, pathname]);

  const prepareGridSave = (mode, changesArray) => {
    let requestPayload = {};
    requestPayload.batch_id = batch;
    requestPayload.update_type = "";
    let updateData = cloneDeep(changesArray);
    if (mode === "all") {
      requestPayload.update_type = "review_update";
      let allData = state.response.data.map((item) => ({
        product_attribute_mapping_id: item.product_attribute_mapping_id,
        product_id: state.response.is_variant
          ? item.variant_id
          : item.product_id,
        tenant_attribute_id: item.tenant_attribute_id,
        tenant_attribute_value: item.tenant_attribute_value,
        new_value: null,
        edited: false,
      }));
      requestPayload.data = allData.map(
        (obj) => updateData.find((o) => o.product_id === obj.product_id) || obj
      );
      return requestPayload;
    } else {
      requestPayload.update_type = "update";
      requestPayload.data = updateData;
      return requestPayload;
    }
  };

  const prepareListSave = (mode, changesArray) => {
    let requestPayload = {};
    requestPayload.batch_id = batch;
    requestPayload.update_type = "";
    let updateData = cloneDeep(changesArray);
    if (mode === "all") {
      requestPayload.update_type = "review_update";
      let allData = [];
      state.response.data.forEach((product) => {
        product.tenant_attributes.forEach((attribute) => {
          let temp = {};
          temp.product_id = state.response.is_variant
            ? product.variant_id
            : product.product_id;

          temp.product_attribute_mapping_id =
            attribute.product_attribute_mapping_id;
          temp.tenant_attribute_id = attribute.tenant_attribute_id;
          temp.tenant_attribute_value = attribute.tenant_attribute_value;
          temp.new_value = null;
          temp.edited = false;
          allData.push(temp);
        });
      });

      requestPayload.data = allData.map(
        (obj) =>
          updateData.find(
            (o) =>
              o.product_id === obj.product_id &&
              o.tenant_attribute_id === obj.tenant_attribute_id
          ) || obj
      );
      return requestPayload;
    } else {
      requestPayload.update_type = "update";
      requestPayload.data = cloneDeep(changesArray);
      return requestPayload;
    }
  };

  const saveEdits = async (mode, requestPayload = false, changesArray = []) => {
    let payload;
    if (requestPayload) {
      payload = requestPayload;
    } else {
      payload =
        getValueForKey("list") === "true"
          ? prepareListSave(mode, changesArray)
          : prepareGridSave(mode, changesArray);
    }
    payload.is_variant = getValueForKey("type") === "true";
    payload.is_all_products = false;

    if (mode === "l1_l2") {
      payload.is_all_products = true;
      payload.is_variant = false;
    }

    try {
      dispatch({ type: "SAVE_INIT" });
      let result;
      if (mode === "delete") {
        result = await axiosInstance.delete(
          `${AS_SINGLE_EDIT_SAVE}?batch_id=${payload.batch_id}&product_id=${
            payload.product_id
          }&is_variant=${
            getValueForKey("type") === "true"
          }&is_all_products=${!payload.current}`
        );
      } else {
        result = await axiosInstance.post(AS_SINGLE_EDIT_SAVE, payload);
      }
      if (result.data.status && result.data.status_code === 200) {
        dispatch({ type: "SAVE_SUCCESS" });
        props.resetReducer();
        handleReplace("forceUpdate", "", history);
        SaveSuccessMessage();
        window.scrollTo(0, 110);
      }
    } catch (error) {
      dispatch({ type: "SAVE_ERROR" });
      SaveErrorMessage();
    }
  };

  return <ProductViewContainer {...props} {...state} saveEdits={saveEdits} />;
};

export default connect(null, { resetReducer })(ProductView);
