import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { fetchPayload } from "./helper";

const useStyles = makeStyles(() => ({
  fontRoot: {
    marginTop: "0px",
  },
}));

export default function StatusText(props) {
  const classes = useStyles();

  const checkNoFilter = () => {
    const payload = fetchPayload();
    payload.filters = payload?.filters?.filter(
      ({ id }) => id !== "product__id"
    );
    const filters = payload?.filters;
    if (filters) {
      return !filters?.some(({ id }) => !isNaN(id));
    }
    return true;
  };

  const labelText = () => {
    if (props.list) {
      if (props.listattribute || (!props.listattribute && checkNoFilter())) {
        return (
          <Typography
            variant="caption"
            classes={{
              root: classes.fontRoot,
            }}
          >
            Showing status of the{" "}
            <span style={{ color: "red" }}>
              {props.isVariant ? "variant" : "product"} attributes
            </span>
          </Typography>
        );
      } else {
        return (
          <Typography
            variant="caption"
            classes={{
              root: classes.fontRoot,
            }}
          >
            Showing status of the{" "}
            <span style={{ color: "red" }}>
              editable attribute (s) of the
              {props.isVariant ? "variants" : "products"}{" "}
            </span>
          </Typography>
        );
      }
    } else if (props.singleEdit) {
      return (
        <Typography
          variant="caption"
          classes={{
            root: classes.fontRoot,
          }}
        >
          Showing status of the{" "}
          <span style={{ color: "red" }}>
            editable attribute (s) of the
            {props.isVariant ? " variants" : " products"}{" "}
          </span>
        </Typography>
      );
    }
    return (
      <Typography
        variant="caption"
        classes={{
          root: classes.fontRoot,
        }}
      >
        Showing status of the{" "}
        <span style={{ color: "red" }}>
          {props.isVariant ? " variant" : " product"} attributes
        </span>
      </Typography>
    );
  };

  return <Box>{labelText()}</Box>;
}
