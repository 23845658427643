// Below helper functions deals with  URI component

/**
 * Decodes the query string using URLSearchParams constructor
 * @return {Object} a URLSearchParams object instance
 */
export const queryString = () =>
  new URLSearchParams(decodeURIComponent(window.location.search));

/**
 * Encodes input object in query string
 * @param  {Object} object having key value pair
 * @return {String} query params in encoded format
 */
export const ObjectToQueryString = (object) =>
  encodeURIComponent(new URLSearchParams(object).toString());

/**
 * Get entries from URLSearchParams object instance
 * @return {Object} all the query param key and values(in string)
 */
export const QueryStringToObject = () => Object.fromEntries(queryString());

/**
 * Get key's value from query string
 * @param  {String} key from query string
 * @return {String} value from query string
 */
export const getValueForKey = (key) => {
  const URIparams = QueryStringToObject();
  return URIparams[key];
};

/**
 * Checks if a value exist for given key from query string
 * @param  {String} key from query string
 * @param  {String} value from query string for a given key
 * @return {Boolean} whether value exist for given key or not
 */
export const keyHasValue = (key, value) =>
  getValueForKey(key)?.split("|")?.includes(value);

/**
 * Updates the query string, if value doesn't exist for given key it adds it and vice versa
 * @param  {String} key from query string
 * @param  {String} value to add/remove for a given key
 * @param  {Object} history
 * @return {undefined} Nothing is returned
 */
export const handleAppend = (key, value, history, isVariant) => {
  const URIparams = QueryStringToObject();
  let currParam = URIparams[key];
  if (keyHasValue(key, value)) {
    let paramArr = currParam.split("|");
    const updatedParam = paramArr.filter((e) => e !== value).join("|");
    const updatedUri = {
      ...URIparams,
      [key]: updatedParam,
    };
    if (paramArr.length === 1) {
      delete updatedUri[key];
    }
    history.push({
      search: `?${ObjectToQueryString(updatedUri)}`,
      page: 1,
    });
  } else {
    pushToAppend(history, URIparams, currParam, value, key, isVariant);
  }
};

const pushToAppend = (history, URIparams, currParam, value, key, isVariant) => {
  let idKey = "productId";
  if (isVariant) {
    idKey = "variantId";
  }
  let excludeProductStatus = false;
  if (key === "product_status" && !URIparams[idKey]) {
    excludeProductStatus = true;
  }
  const payload = {
    ...URIparams,
    [key]: key in URIparams ? currParam + "|" + value : value,
    page: 1,
    [idKey]: idKey in URIparams ? URIparams[idKey] : key,
  };
  if (excludeProductStatus) {
    delete payload[idKey];
  }

  history.push({
    search: `${ObjectToQueryString(payload)}`,
  });
};
/**
 * Updates the query string, if value doesn't exist for given key it adds it and exists, it gets replaced
 * @param  {String} key from query string
 * @param  {String} value to add/remove for a given key
 * @param  {Object} history
 * @return {undefined} Nothing is returned
 */
export const handleReplace = (key, value = "", history = {}) => {
  const URIparams = QueryStringToObject();
  if (key === "forceUpdate") {
    value = !URIparams[key] ? 0 : Number(URIparams[key]) + 1;
  }
  history.push({
    search: `${ObjectToQueryString({
      ...URIparams,
      [key]: value,
    })}`,
  });
};

/**
 * Delete key/value pair from the query string,
 * @param  {String} key from query string
 * @param  {Object} history
 * @return {undefined} Nothing is returned
 */
export const deleteKeyValue = (key, history) => {
  const URIparams = QueryStringToObject();
  delete URIparams[key];
  history.push({
    search: `?${ObjectToQueryString(URIparams)}`,
  });
};

const pushChanges = (payload, query) => {
  for (let pair of queryString().entries()) {
    switch (pair[0]) {
      case "page":
        payload.page_number = pair[1];
        break;
      case "sortBy":
        if (pair[1] === "1") {
          payload.orderby = "+1";
        } else if (pair[1] === "2") {
          payload.orderby = "+2";
        } else {
          payload.orderby = pair[1];
        }
        break;
      case "image_count":
        payload.page_size = pair[1];
        break;
      case "composite_confidence_score":
        query.push({
          id: "composite_confidence_score",
          values: [
            Number(pair[1].split(",")[0]) / 100,
            Number(pair[1].split(",")[1]) / 100,
          ],
        });
        break;
      case "status":
        query.push({
          id: "status",
          value: pair[1],
        });
        break;
      case "showall":
        payload.showall = pair[1] === "true";
        break;
      case "list":
        payload.list = pair[1] === "true";
        break;
      case "type":
        payload.is_variant = pair[1] === "true";
        break;
      case "productId":
        payload.productId = pair[1] ? Number(pair[1]) : null;
        break;
      case "variantId":
        payload.variantId = pair[1] ? Number(pair[1]) : null;
        break;
      case "product__id":
        payload.product__id = pair[1] ? Number(pair[1]) : null;
        break;
      case "forceUpdate":
        break;
      default:
        query.push({
          id: pair[0],
          values: pair[1].split("|"),
        });
    }
  }
};

export const fetchPayload = () => {
  let payload = {};
  let query = [];
  payload.page_size = 40;
  payload.page_number = 1;
  payload.orderby = "-1";
  pushChanges(payload, query);
  payload.filters = query;
  if (!payload.list) {
    delete payload.showall;
  }
  payload.tenant_attribute_id = payload.productId;
  if (payload.is_variant) {
    payload.tenant_attribute_id = payload.variantId;
  }
  delete payload.productId;
  delete payload.variantId;
  return payload;
};

export const handleClearAll = (history) => {
  let queryToRetain = {};

  for (let pair of queryString().entries()) {
    switch (pair[0]) {
      case "page":
        queryToRetain.page = pair[1];
        break;
      case "sortBy":
        queryToRetain.sortBy = pair[1];
        break;
      case "image_count":
        queryToRetain.image_count = pair[1];
        break;
      case "status":
        queryToRetain.status = pair[1];
        break;
      case "showall":
        queryToRetain.showall = pair[1] === "true";
        break;
      case "list":
        queryToRetain.list = pair[1] === "true";
        break;
      case "type":
        queryToRetain.type = pair[1] === "true";
        break;
      case "productId":
        queryToRetain.productId = pair[1] ? Number(pair[1]) : null;
        break;
      case "variantId":
        queryToRetain.variantId = pair[1] ? Number(pair[1]) : null;
        break;
      default:
        break;
    }
  }

  history.push({
    search: `?${ObjectToQueryString(queryToRetain)}`,
  });
};
