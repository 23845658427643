import React, { useState } from "react";
import styled from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CloseIcon from "@material-ui/icons/Close";
import ConfirmPrompt from "../../../../../commonComponents/confirmPrompt";

const VariantDeleteButton = ({ changesArr, deleteVariant, productDetails }) => {
  const [promptMessage, setPromptMessage] = useState({
    status: false,
    message: "",
    reset: "false",
  });

  const handleDelete = (e) => {
    e.stopPropagation();

    if (changesArr) {
      const message =
        "Are you sure you want to leave this page without saving changes and delete this product from this batch ?";
      setPromptMessage({ status: true, message: message, reset: true });
    } else {
      const message =
        "Are you sure you want to delete this variant from this batch?";
      setPromptMessage({ status: true, message: message, reset: false });
    }
  };

  return (
    <ActionsDiv
      onClick={(e) => {
        e.stopPropagation();
      }}
      id={`RecategorisationDeletionDiv-${productDetails.product_id}`}
    >
      <IconButton
        id={`RecategorisationBtn-${productDetails.product_id}`}
        aria-label="recategorization"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={() => null}
        style={{ visibility: "hidden", pointerEvents: "none" }}
      >
        <ArrowDropDownIcon
          id="L1L2ReCatBtn"
          color={"primary"}
          style={{
            backgroundColor: "#fff",
            borderRadius: "50%",
            width: "25px",
            height: "25px",
          }}
        />
      </IconButton>
      <IconButton
        id={`DeletionBtn-${productDetails.product_id}`}
        aria-label="untag"
        aria-controls="confirm-menu"
        aria-haspopup="true"
        onClick={handleDelete}
      >
        <CloseIcon
          id="L1L2DeleteBtn"
          color={"primary"}
          style={{
            backgroundColor: "#fff",
            borderRadius: "50%",
            width: "25px",
            height: "25px",
            padding: "4px",
          }}
        />
      </IconButton>
      {promptMessage.status && (
        <ConfirmPrompt
          title={"Confirm"}
          message={promptMessage.message}
          confirmCallback={(val) => {
            if (val) {
              deleteVariant(promptMessage.reset, productDetails.product_id);
            }
            setPromptMessage({
              status: false,
              message: "",
              reset: "false",
            });
          }}
        />
      )}
    </ActionsDiv>
  );
};

export default VariantDeleteButton;

const ActionsDiv = styled.div`
  position: absolute;
  top: -10px;
  left: -10px;
  display: flex;
  width: 220px;
  justify-content: space-between;
  cursor: default !important;
`;
