import { Box, makeStyles } from "@material-ui/core";
import React from "react";
import ImageCardWrapper from "./ImageCardWrapper";
import ImageSkeleton from "./ImageSkeleton";

const ProductViewGrid = ({ response, ...props }) => {
  const classes = useStyles();

  if (props.isloading || !props.attributes[1]) {
    return <ImageSkeleton count={12} />;
  }

  if (!response?.data?.length) {
    return <div className={classes.noData}>No images found...</div>;
  }

  return (
    <Box className={classes.gridWrapper}>
      {response?.data?.map((product, i) => (
        <ImageCardWrapper
          {...props}
          key={response.is_variant ? product.variant_id : product.product_id}
          imageUrl={product.thumbnail_image_url}
          imageUrlHighReso={product.image_url}
          selected={product.selected}
          index={i}
          handleRecategorization={props.saveEdits}
          dropdownData={
            props.attributes[product.tenant_attribute_id]
              ?.tenant_attribute_value_list
          }
          productDetails={{
            id: product.product_id,
            product_attribute_mapping_id: product.product_attribute_mapping_id,
            tenant_attribute_value: product.tenant_attribute_value,
            tenant_attribute_id: product.tenant_attribute_id,
            product_attribute_status: product.product_attribute_status,
            product_status: product.product_status,
            product_display_status: product.product_display_status,
            L1_category_detail: product.L1_category_detail,
            L2_category_detail: product.L2_category_detail,
            product_id: response.is_variant
              ? product.variant_id
              : product.product_id,
          }}
          is_variant={response.is_variant}
        />
      ))}
    </Box>
  );
};

export default ProductViewGrid;

const useStyles = makeStyles((theme) => ({
  gridWrapper: {
    fontSize: 14,
    height: "fit-content",
    overflowY: "visible",
    margin: "0 -40px",
    display: "inline-flex",
    justifyContent: "start",
    flexWrap: "wrap",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },

    "& .product-attribute-select": {
      width: 200,
      "&:last-child": {
        marginBottom: 40,
      },
    },
  },
  noData: {
    color: "grey",
    margin: 40,
    fontSize: 14,
  },
}));
